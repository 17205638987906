export function articles() {
  return [
    {
      id: "HowToMakeFriendsWithChatGpt",
      authorId: "AlisonKing",
      author: "Alison King",
      posted: "May 1, 2023",
      heading: "How To Make Friends With ChatGPT",
      thumbnail: "https://media.graphassets.com/VAXRIDbUTRS9dDCBH9kJ",
      route: "/articles/how-to-make-friends-with-chat-gpt",
      category: "Writing",
      preview:
        "You're probably aware that artificial intelligence, or AI, is making headlines. ChatGPT, an artificial intelligence chatbot created by OpenAI, was released in November 2022 and now has over one hundred million users using its free services."
    },
    {
      id: "IWrote1000WordsFor30DaysAndHereIsWhatHappened",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Mar 21, 2023",
      heading: "I Wrote 1,000 Words for 30 Days and Here’s What Happened",
      thumbnail: "https://media.graphassets.com/CcXNX5RZQuSk4yu4Mcvi",
      route: "/articles/i-wrote-1000-words-for-30-days-and-here-is-what-happened",
      category: "Writing",
      preview:
        "I'm a sucker for endurance sports. Specifically, I’m a sucker for watching marathon highlights on YouTube from the comfort of my couch while snacking on liberal amounts of popcorn. I’m fascinated by the grim determination on the faces of a star-studded cast of athletes"
    },
    {
      id: "WhenShouldICallMyselfAWriter",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Feb 17, 2023",
      heading: "When Should I Call Myself a Writer?",
      thumbnail: "https://media.graphassets.com/davdMHswQ1qH15LNrWiq",
      route: "/articles/when-should-i-call-myself-a-writer",
      category: "Writing",
      preview:
        "I am not a writer. I’ve been fooling myself and other people. No, this isn’t me magically reading your mind. It’s actually something John Steinbeck wrote in his journal in 1938 while he was struggling to draft The Grapes of Wrath."
    },
    {
      id: "WhatShouldIWriteAboutFiveWaysToFindNewWritingIdeas",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Jan 27, 2023",
      heading: "What Should I Write About? Five Ways To Find New Writing Ideas",
      thumbnail: "https://media.graphassets.com/xoOMWOhTtqyua7ofuORK",
      route: "/articles/what-should-i-write-about-five-ways-to-find-new-writing-ideas",
      category: "Writing",
      preview:
        "You’re lying on the floor in crumpled, coffee-stained clothes, your hair a mess from all the times you’ve pulled at it. Over in the corner, your laptop lights up with the blinking of your cursor on a background that sends chills down your spin"
    },
    {
      id: "FiveTraitsOfAnUnforgettableScene",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Sep 17, 2021",
      heading: "Five Traits of an Unforgettable Scene",
      preview:
        "What is the most unforgettable scene you've ever read? Did the vivid imagery capture your imagination, the hero's dark hair flying behind them as they ran towards their hopeless goal?",
      description:
        "Mastering the art of scene writing is critical to maintaining your reader’s attention throughout your work. Here are five traits of an unforgettable scene to keep your reader hooked.",
      thumbnail: "https://media.graphassets.com/6HMxuHQ1a3Uf1GywZf7Q",
      route: "/articles/five-traits-of-an-unforgettable-scene",
      category: "Writing"
    },
    {
      id: "FiveWaysToCreateNewSettingsInYourWriting",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Jun 28, 2021",
      heading: "Five Ways to Create New Settings in Your Writing",
      thumbnail: "https://media.graphassets.com/7T1zLZpZRayYBEDViK1y",
      route: "/articles/five-ways-to-create-new-settings-in-your-writing",
      category: "Writing",
      preview: "Creating immersive settings is a critical part of writing both fiction and nonfiction. Some of us may have a natural talent for waxing poetic about the culture, history, and geography of our literary worlds, whereas the rest of us need a little extra"
    },
    {
      id: "SubmissionsTheHarshRealityAndHowToImproveYourOdds",
      authorId: "TheaPueschel",
      author: "Thea Pueschel",
      posted: "Jun 3, 2021",
      heading: "Submissions: The Harsh Reality and How to Improve Your Odds",
      thumbnail: "https://media.graphassets.com/UyrxZEkeR1WZgOrXzhZg",
      route: "/articles/submissions-the-harsh-reality-and-how-to-improve-your-odds",
      category: "Publishing",
      preview: "A rejection letter leaves many writers devastated. For years, I would submit one to three pieces a year to literary magazines, and if the work received a rejection, it became dead to me. My nonfiction wellness articles had a "
    },
    {
      id: "FiveStepsToImprovePacingInYourStory",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Feb 11, 2021",
      heading: "Five Steps To Improve Pacing in Your Story",
      thumbnail: "https://media.graphassets.com/TUKtZIJFSAO2JnZJAygg",
      route: "/articles/five-steps-to-improve-pacing-in-your-story",
      category: "Writing",
      preview: "Unlike tone and voice, two of the more slippery elements of storytelling, pacing is an element that lends itself to more fine-grained control. Whether you’re working on a first draft or editing a later one, here’s how you can maneuver pacing in your story."
    },
    {
      id: "TheElementsOfStorytellingForNovelists",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Jan 19, 2021",
      heading: "The Elements of Storytelling for Novelists",
      thumbnail: "https://media.graphassets.com/zufGad7T8aAa4GCYdNkG",
      route: "/articles/the-elements-of-storytelling-for-novelists",
      category: "Writing",
      preview: "Writing a novel involves juggling multiple elements of storytelling, like plot, characters, point of view, setting, pacing, and perhaps the most elusive one of all, tone. Many successful combinations of these elements are possible,"
    },
    {
      id: "HowToKeepYourWritingOnTrack",
      authorId: "RennieSaunders",
      author: "Rennie Saunders",
      posted: "Jan 13, 2021",
      heading: "How to Keep Your Writing on Track",
      thumbnail: "https://media.graphassets.com/7XiiEap0RZGVkFg1PuG9",
      route: "/articles/how-to-keep-your-writing-on-track",
      category: "Writing",
      preview: "As the founder of Shut Up & Write!, I’ve spent over a decade helping writers conquer distractions so they can focus on their writing. Though some distractions may vary, most of us have many of the same core issues keeping us from the page. "
    },
    {
      id: "HowToWorkWithAnIllustrator",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      posted: "Dec 16, 2020",
      heading: "How to Work With an Illustrator",
      description:
        "As a writer, you get to see the images you’ve been imagining in your mind take shape in the physical world. Often, collaborating with another artist can create an explosion of creativity as they ask questions you wouldn’t think to ask yourself.",
      thumbnail: "https://media.graphassets.com/52bgJg6GQCq2aWL0gRAl",
      route: "/articles/how-to-work-with-an-illustrator",
      category: "Publishing",
      preview: "Working with an illustrator can be a transformative experience. As a writer, you get to see the images you’ve been imagining in your mind take shape in the physical world. Often, collaborating with another artist can create an explosion of creativity as they ask questions you woul"
    },
    {
      id: "ShouldYouSelfPublishOrTraditionallyPublish",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Dec 8, 2020",
      heading: "Should You Self-Publish or Traditionally Publish?",
      thumbnail: "https://media.graphassets.com/f8dkHOoTu6Zg6SJDzHtt",
      route: "/articles/should-you-self-publish-or-traditionally-publish",
      category: "Publishing",
      preview: "When you think about the word “publishing,” you probably picture a bespectacled editor, red pen in hand, poring over your manuscript from behind their mahogany desk. This scene belongs in the realm of traditional publishing, a world that authors typically canno"
    },
    {
      id: "HowToSelfEditInSixPainlessSteps",
      authorId: "AlisonKing",
      author: "Alison King",
      posted: "Nov 20, 2020",
      heading: "How to Self-Edit in Six Painless Steps",
      thumbnail: "https://media.graphassets.com/mRM9YE1SfGu4PcvIYPJe",
      route: "/articles/how-to-self-edit-in-six-painless-steps",
      category: "Editing",
      preview: "Welcome aboard! This is your editor speaking. On this trip through your recently completed draft, we’re going to run through a few easy steps to take while you re-read. These steps will teach you to recognize common mistakes and eliminate"
    },
    {
      id: "StayingCreativeWhileTheWorldIsBurning",
      authorId: "RennieSaunders",
      author: "Rennie Saunders",
      posted: "Nov 18, 2020",
      heading: "Staying Creative While the World is Burning",
      thumbnail: "https://media.graphassets.com/gsUuoWGuQ4WuK1trsOwm",
      route: "/articles/staying-creative-while-the-world-is-burning",
      category: "Writing",
      preview: "It’s difficult. Let me just say that at the beginning. Creativity can be a struggle even when you have equilibrium in your life. But when daily challenges disrupt your mental and emotional balance, finding time to write, paint, or play music feels impossibly difficult."
    },
    {
      id: "MakeWritingAHabitNotAChoice",
      authorId: "ClaireSplan",
      author: "Claire Splan",
      posted: "Nov 18, 2020",
      heading: "Make Writing a Habit, Not a Choice",
      thumbnail: "https://media.graphassets.com/1niB9F6RiacrviXT6YL6",
      route: "/articles/make-writing-a-habit-not-a-choice",
      category: "Writing",
      preview: "In the past I’ve attributed much of my inability to write on any kind of regular schedule to a problem with procrastination. I intend to write; I just don’t get around to it as often as I want to. But I’ve begun to realize that procrastination is just the surface issue. "
    },
    {
      id: "HowToFindALiteraryAgent",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Oct 26, 2020",
      heading: "How to Find a Literary Agent",
      thumbnail: "https://media.graphassets.com/10U99ylGTZWdvurzhWWH",
      route: "/articles/how-to-find-a-literary-agent",
      category: "Publishing",
      preview: "Finding a literary agent is a crucial step in the publication process for those looking to pursue traditional publishing rather than self-publishing. First, let’s talk about what a literary agent is and how they can help you"
    },
    {
      id: "BetweenEditingAndSubmission",
      authorId: "JulieHoward",
      author: "Julie Howard",
      posted: "Sep 29, 2020",
      heading: "Between Editing and Submission",
      thumbnail: "https://media.graphassets.com/1EfTHruJQni4sltSfwFx",
      route: "/articles/between-editing-and-submission",
      category: "Publishing",
      preview: "You’ve just completed your novel, and you can’t wait to get it out to agents or publishers. Whoa — don’t press send yet! Even when the manuscript has been edited, rewritten, beta read, edited again, and polished as much as possible, still don’t send it."
    },
    {
      id: "HowToEditTheFirstDraftOfYourNovel",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Sep 21, 2020",
      heading: "How to Edit the First Draft of Your Novel",
      thumbnail: "https://media.graphassets.com/ihAvZx2dThCPENdph3w0",
      route: "/articles/how-to-edit-the-first-draft-of-your-novel",
      category: "Editing",
      preview: "In July this year, I completed the first draft of my manuscript! It felt glorious to reach this milestone after the months of emotional ups and downs which typically comprise the development of a first draft. Luckily, I had the support of the Shut Up & Write! community throughout the process."
    },
    {
      id: "HowICompletedMyFirstNovelWithTheHelpOfShutUpAndWrite",
      authorId: "CristianAluas",
      author: "Cristian Aluas",
      posted: "Aug 31, 2020",
      heading: "How I Completed My First Novel With the Help of Shut Up & Write",
      thumbnail: "https://media.graphassets.com/yeIx6CnQtWdVmgjXor9n",
      route: "/articles/how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write",
      category: "Writing",
      preview: "It took me two and a half years to write and edit my first nonfiction novel, and I couldn’t have completed it without the encouragement of Shut Up & Write!’s NYC chapter. It’s difficult to motivate yourself to work on your craft. Time is limited and precious. "
    },
    {
      id: "FindingAndWorkingWithABetaReader",
      authorId: "AlisonKing",
      author: "Alison King",
      posted: "Aug 18, 2020",
      heading: "Finding and Working With a Beta Reader",
      thumbnail: "https://media.graphassets.com/CL2dY6vUQKaynJFNGbwZ",
      route: "/articles/finding-and-working-with-a-beta-reader",
      category: "Editing",
      preview: "So you’ve finished your first draft. Congratulations! Take a deep breath and celebrate this victory. But don’t break out the champagne just yet. Brace yourself, because it’s time to let your draft out into the world and into the hands of readers who can give you feedback. But who should this audience be, and how will you find them?"
    },
    {
      id: "HowToGetABookCoverYoureProudOf",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Aug 14, 2020",
      heading: "How to Get a Book Cover You’re Proud Of",
      thumbnail: "https://media.graphassets.com/94sZSFMLQ6f0EpaLiqd7",
      route: "/articles/how-to-get-a-book-cover-youre-proud-of",
      category: "Publishing",
      preview: "If you’re like most authors, you’ve already daydreamed about what your book will look like. Once you’re ready to move along the path to publication, you have the chance to see those daydreams become reality. Whether you’re set on a particular vi"
    },
    {
      id: "TricksForWritingUnderPressure",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      posted: "Jul 15, 2020",
      heading: "Tricks for Writing Under Pressure",
      thumbnail: "https://media.graphassets.com/VTizxlsSReUVjl7Gk2TQ",
      route: "/articles/tricks-for-writing-under-pressure",
      category: "Writing",
      preview: "For most of my life I have been at odds with writing under pressure. Whether it was a school paper deadline, a piece for work, or a personal project, I would find other projects that interested me far more than the one at hand, and habitually procrastinate "
    },
    {
      id: "HowToGetFeedbackOnYourWriting",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Jul 6, 2020",
      heading: "How to Get Feedback on Your Writing",
      thumbnail: "https://media.graphassets.com/M2fgScTfKf5AtMF5EfKQ",
      route: "/articles/how-to-get-feedback-on-your-writing",
      category: "Editing",
      preview: "At some point in your writing journey, you will want to know what other people think about your work. That’s when getting high-quality feedback from trusted readers becomes really important. If you’re wondering who and when to ask for feedback"
    },
    {
      id: "FourEssentialStepsToDevelopingYourWritingPractice",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      posted: "May 27, 2020",
      heading: "Four Essential Steps to Developing Your Writing Practice",
      thumbnail: "https://media.graphassets.com/C6bDRIexSaO42vzDI54B",
      route: "/articles/four-essential-steps-to-developing-your-writing-practice",
      category: "Writing",
      preview: "One of the more difficult aspects of writing is carving out a regular time to write. It’s not enough to “free up time” or “squeeze in a few minutes here and there.” One of the reasons Shut Up & Write! is so successful is that we make an hour a week the minimum co"
    },
    {
      id: "SevenStrategiesToGetYouWritingAgain",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      posted: "May 26, 2020",
      heading: "Seven Strategies to Get You Writing Again",
      thumbnail: "https://media.graphassets.com/zlNMrmZbQa6B7e50KAc4",
      route: "/articles/seven-strategies-to-get-you-writing-again",
      category: "Writing",
      preview: "Any writer, at one point of another, has had their inspiration tested on a project. However, there are many ways you can work around a motivation roadblock. Most of the time all you need is a little boost to get yourself writing again. Here are some tips to get your project back on track."
    },
    {
      id: "TheWritersGuideToCopyright",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Apr 6, 2020",
      heading: "The Writer’s Guide to Copyright",
      thumbnail: "https://media.graphassets.com/euGEkbf8TqyvJM3tGdN6",
      route: "/articles/the-writers-guide-to-copyright",
      category: "Publishing",
      preview: "We know copyright is not the reason you became a writer, but whether you’re new to writing or a published author, knowing about copyright can help protect your rights. So, we’re here to help light the way on this dry subject. This summary will help you understand"
    },
    {
      id: "TenBestPracticesForWritingFromHome",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Mar 20, 2020",
      heading: "10 Best Practices for Writing From Home",
      thumbnail: "https://media.graphassets.com/JxNUeqiWRmqJIRXi2u3g",
      route: "/articles/10-best-practices-for-writing-from-home",
      category: "Writing",
      preview: "The COVID-19 pandemic has left few of us unaffected. For those of you who suddenly find yourselves in the position of having to work from home, we’ve compiled a list of best practices tailored to help you stay on track with your writing. Remember that "
    },
    {
      id: "TheWritersGuideToEditors",
      authorId: "AlisonKing",
      author: "Alison King",
      posted: "Feb 21, 2020",
      heading: "The Writer’s Guide to Editors",
      thumbnail: "https://media.graphassets.com/tDfqmZuRS12a28n9TKlY",
      route: "/articles/the-writers-guide-to-editors",
      category: "Editing",
      preview: "At long last, you have a completed draft in hand. Or maybe you don’t, but you’d really like to have one someday. Whether you have a polished piece or a half-finished project, you’ll likely face this age-old question: do you need an editor?"
    },
    {
      id: "CreatingYourWritingEnvironment",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      posted: "Jan 29, 2020",
      heading: "Creating Your Writing Environment",
      thumbnail: "https://media.graphassets.com/oYDdPAOQ2iQyHRWdXFFh",
      route: "/articles/creating-your-writing-environment",
      category: "Writing",
      preview: "Do you have a place where you write consistently? If not, this guide will help you create a focused environment so you can be at your best when writing. First, your writing environment should be a place you feel safe. It should engage your thoughts and help you focus. Ideally it should be a place where you can "
    },
    {
      id: "FiveWaysToSilenceYourInnerCriticWhileDrafting",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Dec 1, 2019",
      heading: "Five Ways to Silence Your Inner Critic While Drafting",
      thumbnail: "https://media.graphassets.com/Yl1jWzlXSZSpbKXAQGJW",
      route: "/articles/five-ways-to-silence-your-inner-critic-while-drafting",
      category: "Writing",
      preview: "Those of us just starting out on our writing journeys can sometimes get bogged down by inner doubts. The worst critic is the one living inside your own head, available with scathing retorts on a relentless, 24/7 schedule."
    },
    {
      id: "ShutUpAndWritesNanowrimoSurvivalGuide",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      posted: "Nov 1, 2019",
      heading: "Shut Up & Write!'s NaNoWriMo Survival Guide",
      thumbnail: "https://media.graphassets.com/8wUE3ccFS5ikuMmVlhoZ",
      route: "/articles/shut-up-and-writes-nanowrimo-survival-guide",
      category: "Writing",
      preview: "November 1st, the first day of NaNoWriMo, is the start of a 30-day journey toward 50,000 words. It’s going to be intense and exhilarating and emotional. It’s not just about the commitment, the anticipation of success or fear of failure—it’s about"
    },
    {
      id: "FourEasyStepsToAWritingPlan",
      authorId: "CatherineEllsworthon",
      author: "Catherine Ellsworthon",
      posted: "Feb 12, 2018",
      heading: "Four Easy Steps to a Writing Plan",
      thumbnail: "https://media.graphassets.com/7r6gjq5YSOp2o06tvRLG",
      route: "/articles/4-easy-steps-to-a-writing-plan",
      category: "Writing",
      preview: "Have you ever put together a writing plan? In case that sounds strange, consider this – how often do we hear that a bit of planning and organization can make us more effective? We do it at work in managing our tasks and our priorities, menu plan"
    },
    {
      id: "FiveSimpleHabitsToBoostYourWriting",
      authorId: "CatherineEllsworthon",
      author: "Catherine Ellsworthon",
      posted: "Jan 4, 2018",
      heading: "Five Simple Habits To Boost Your Writing",
      thumbnail: "https://media.graphassets.com/sY1pwJpRtuuhYk1YqNIz",
      route: "/articles/5-simple-habits-to-boost-your-writing",
      category: "Writing",
      preview: "The beginning of a new year always seems to be magical time, a time when we reach for what we want most. I want to share some simple habits to help you capture that energy and bring it into the year as a whole to really kickstart your writing."
    },
    {
      id: "ItsOkayToBeEditedReally",
      authorId: "JulieHoward",
      author: "Julie Howard",
      posted: "Sep 1, 2017",
      heading: "It’s Okay To Be Edited. Really.",
      thumbnail: "https://media.graphassets.com/XwWARFTZSxGAJaui6C2d",
      route: "/articles/its-okay-to-be-edited-really",
      category: "Editing",
      preview: "This isn’t what I expected. There are the horror stories of plot changes, severe cuts, title changes, red ink splashing across the electronic page. Working with a book editor is supposed to be a soul-crushing, ego-blasting, albeit improving, experience."
    },
    {
      id: "AttendingTheMuseOrYourWritingDiscipline",
      authorId: "RennieSaunders",
      author: "Rennie Saunders",
      posted: "Feb 28, 2017",
      heading: "“Attending the Muse,” or Your Writing Discipline",
      thumbnail: "https://media.graphassets.com/but9bLTNRxG94rux8XaK",
      route: "/articles/attending-the-muse-or-your-writing-discipline",
      category: "Writing",
      preview: "Your Muse is the outward personification of your most creative aspirations – emotional poetry or rocking song, epic play or lovely sonnet, exciting novel or mind-bending short story, mathematically challenging cantata or romantic symphony, tragic history "
    },
    {
      id: "FiveGeekyHobbiesForWritersThatArentReading",
      authorId: "KellieDoherty",
      author: "Kellie Doherty",
      posted: "Jan 10, 2017",
      heading: "Five Geeky Hobbies for Writers (That Aren’t Reading!)",
      thumbnail: "https://media.graphassets.com/wfUJKBoSVKVVmVYyyXuw",
      route: "/articles/five-geeky-hobbies-for-writers-that-arent-reading",
      category: "Writing",
      preview: "Characters. Plot lines. Descriptions. Page after page of words. Whether you do it by yourself in your writing nook, among strangers at a coffee house, or around friends at a meetup, writing is certainly fun. But it calls for long hours in front of a computer scr"
    },
    {
      id: "AfterTheFirstDraftProTipsForEditingYourWork",
      authorId: "ClaireSplan",
      author: "Claire Splan",
      posted: "May 18, 2016",
      heading: "After the First Draft, Pro Tips for Editing Your Work",
      thumbnail: "https://media.graphassets.com/34HINKLmRnKSJwvUvbV7",
      route: "/articles/after-the-first-draft-pro-tips-for-editing-your-work",
      category: "Editing",
      preview: "So you’ve finished your book. Let’s just take a moment to contemplate and revel in what a huge thing you’ve accomplished. Lots of people talk about writing books but a lot fewer actually get it done. So, well done, you! Now it’s time to take it to the next level: editing your work."
    },
    {
      id: "BeginningTipsForWritersWorkingStylesAndWip",
      authorId: "CindyPowers",
      author: "Cindy Powers",
      posted: "Apr 16, 2016",
      heading: "Beginning Tips for Writers: Working Styles and WIP",
      thumbnail: "https://media.graphassets.com/0yhuMFhCSYm0irv27Grp",
      route: "/articles/beginning-tips-for-writers-working-styles-and-wip",
      category: "Writing",
      preview: "When you’re starting out and getting used to writing on a regular basis, learning about your working style is probably the next step. Everyone has their own style. Even if you’re familiar and comfortable with yours you might have to change or adapt it to suit a "
    },
    {
      id: "PerfectionismDistractsYouFromWritingPerfectly",
      authorId: "CatherineEllsworthon",
      author: "Catherine Ellsworthon",
      posted: "Apr 5, 2016",
      heading: "Perfectionism Distracts You From Writing Perfectly",
      thumbnail: "https://media.graphassets.com/VMaTmI4KR5Glk6tHuqyS",
      route: "/articles/perfectionism-distracts-you-from-writing-perfectly",
      category: "Writing",
      preview: "As you’re writing, it’s normal to want your words to be perfectly aligned with your vision; inside all of us is an Inner Editor. This is the voice that listens as we write and pauses us with thoughts like “you can find a better word” or “well that’s not really clear,” "
    },
    {
      id: "ConquerProcrastinationAndGetWriting",
      authorId: "CatherineEllsworthon",
      author: "Catherine Ellsworthon",
      posted: "Apr 3, 2016",
      heading: "Conquer Procrastination and Get Writing",
      thumbnail: "https://media.graphassets.com/rwpW4IkMQReKV9MY2Nmp",
      route: "/articles/conquer-procrastination-and-get-writing",
      category: "Writing",
      preview: "You’re excited to write. You talk about doing it. You make plans to do it. Trouble is, you’re not writing. are first starting out. Everything else seems to get in the way. Here’s how to get yourself on track when you find yourself procrastinating."
    },
    {
      id: "BeginningTipsForWritersTheBasics",
      authorId: "CindyPowers",
      author: "Cindy Powers",
      posted: "Feb 13, 2016",
      heading: "Beginning Tips for Writers: The Basics",
      thumbnail: "https://media.graphassets.com/lZxWaS8Ra6zcX4KcZUdQ",
      route: "/articles/beginning-tips-for-writers-the-basics",
      category: "Writing",
      preview: "I wrote this series a long time ago to share what I’ve learned. Since I’ve been writing regularly for quite a few years, I thought this would be fun to update. None of this advice is cut in stone and what works for me may not work for you."
    }
  ];
}

export function authors() {
  return [
    {
      id: "AlisonKing",
      name: "Alison King",
      initials: "AK",
      bio:
        "Shut Up & Write's Product Manager, Alison King, is also the team's in-house editor. Alison is a writer and lifelong music geek, and can be found in Berkeley, CA, or at @alison_king on Twitter.",
      articles: ["HowToMakeFriendsWithChatGpt", "HowToSelfEditInSixPainlessSteps", "FindingAndWorkingWithABetaReader", "TheWritersGuideToEditors"]
    },
    {
      id: "CatherineEllsworthon",
      name: "Catherine Ellsworthon",
      initials: "CE",
      bio: "",
      articles: ["FourEasyStepsToAWritingPlan", "FiveSimpleHabitsToBoostYourWriting", "PerfectionismDistractsYouFromWritingPerfectly", "ConquerProcrastinationAndGetWriting"]
    },
    {
      id: "CindyPowers",
      name: "Cindy Powers",
      initials: "CP",
      bio:
        "Writer of male/male romance stories and fan fiction. Find me Twitter and Tumblr hanging out with my friends, working on my stories, blogging, or immersed in learning something new. Be yourself, live your passion.",
      articles: ["BeginningTipsForWritersTheBasics", "BeginningTipsForWritersWorkingStylesAndWip"]
    },
    {
      id: "ClaireSplan",
      name: "Claire Splan",
      initials: "CS",
      bio:
        "Claire Splan writes fiction and nonfiction and runs a weekly Shut Up & Write session in Alameda. She has been a professional editor for what feels like a thousand years and has worked on literally hundreds of books on a wide range of topics. Her recently published book <a href='https://www.amazon.com/Not-So-Hot-Tomatoes-Growing-Delicious-Climates/dp/B093M54Z6G/ref=sr_1_1?dchild=1&keywords=not+so+hot+tomatoes&qid=1623429408&sr=8-1'>Not So Hot Tomatoes: Growing Tomatoes in Cooler Climates</a> is now available on Amazon.",
      articles: ["AfterTheFirstDraftProTipsForEditingYourWork", "MakeWritingAHabitNotAChoice"]
    },
    {
      id: "CristianAluas",
      name: "Cristian Aluas",
      initials: "CA",
      bio:
        "Shut Up & Write member Cristian S. Aluas is an artist and writer living in Manhattan. He's the author of a new book titled <a href='https://www.amazon.com/ITS-LIVING-Surviving-Freelancer-Professionals/dp/1709485345'>IT'S A LIVING: Surviving as a Freelancer in the 21st Century, The Ultimate Guide to Success for Artists and Creative Professionals</a>. Readers can follow Cristian on Instagram at @masterpieceartschool.",
      articles: ["HowICompletedMyFirstNovelWithTheHelpOfShutUpAndWrite"]
    },
    {
      id: "JeremyThweatt",
      name: "Jeremy Thweatt",
      initials: "JT",
      bio:
        "Jeremy manages the content department and loves engaging our members through educational blog posts about the craft of writing, while continually reading about all things writing-related in order to distill what he learns into content for Shut Up & Write. He is currently co-authoring a science fiction novel with Rennie.",
      articles: [
        "HowToWorkWithAnIllustrator",
        "TricksForWritingUnderPressure",
        "FourEssentialStepsToDevelopingYourWritingPractice",
        "SevenStrategiesToGetYouWritingAgain",
        "CreatingYourWritingEnvironment",
        "ShutUpAndWritesNanowrimoSurvivalGuide"
      ]
    },
    {
      id: "JulieHoward",
      name: "Julie Howard",
      initials: "JH",
      bio:
        "Boise City Manager at Shut Up & Write! Julie Howard leads Shut Up & Write groups in Boise, Idaho. She is the author of <em>Crime and Paradise</em>, <em>Crime Times Two</em>, and <em>Spirited Quest</em>. She is currently on pins and needles waiting to see if her fourth book will be contracted by her publisher, The Wild Rose Press. More about her and her books are at www.juliemhoward.com or find her on Twitter at @_juliemhoward.",
      articles: ["BetweenEditingAndSubmission", "ItsOkayToBeEditedReally"]
    },
    {
      id: "KellieDoherty",
      name: "Kellie Doherty",
      initials: "KD",
      bio:
        "Kellie Doherty is from Eagle River, Alaska, though she now resides in Portland, Oregon. Living in Alaska for a good portion of her life gave her an appreciation for nature, a hankering for a good cup of tea or cider, and a passion for all things below zero. During those dark winter nights, she kept herself busy by writing stories and creating characters. After spending time in already created universes writing fan fiction, she decided to build her own. Her first book <a href='https://www.amazon.com/Finding-Hekate-Cicatrix-Duology-1/dp/1942976062'>Finding Hekate</a> was published by Desert Palm Press in April 2016, and she's currently working on the sequel titled Losing Hold. Her work has also been featured in Flight (Mischief Corner Books, 2016), 49 Writers Alaska Shorts, Pathos Literary Magazine, the Chugiak Eagle River Star, F Magazine, and Alaska Women Speak.",
      articles: ["FiveGeekyHobbiesForWritersThatArentReading"]
    },
    {
      id: "LiraSamanta",
      name: "Lira Samanta",
      initials: "LS",
      bio:
        "Lira Samanta is Data Analytics & Content Operations Manager at Shut Up & Write!. She writes poetry and literary & speculative fiction and is currently at work on her umpteenth novel.",
      articles: [
        "IWrote1000WordsFor30DaysAndHereIsWhatHappened",
        "WhenShouldICallMyselfAWriter",
        "WhatShouldIWriteAboutFiveWaysToFindNewWritingIdeas",
        "FiveTraitsOfAnUnforgettableScene",
        "FiveWaysToCreateNewSettingsInYourWriting",
        "FiveStepsToImprovePacingInYourStory",
        "TheElementsOfStorytellingForNovelists",
        "ShouldYouSelfPublishOrTraditionallyPublish",
        "HowToFindALiteraryAgent",
        "HowToEditTheFirstDraftOfYourNovel",
        "HowToGetABookCoverYoureProudOf",
        "HowToGetFeedbackOnYourWriting",
        "TheWritersGuideToCopyright",
        "TenBestPracticesForWritingFromHome",
        "FiveWaysToSilenceYourInnerCriticWhileDrafting"
      ]
    },
    {
      id: "RennieSaunders",
      name: "Rennie Saunders",
      initials: "RS",
      bio:
        "Rennie founded Shut Up & Write! in 2007 following a desire to meet fellow writers while working on a series of science fiction novels. Rennie spends an inordinate amount of time reading Wikipedia and Discover Magazine articles as research for his science fiction writing, practices Indonesian martial arts and cooks wholesome dinners for his family. His novella, <a href='https://www.amazon.com/gp/product/B01B7LKPSW/ref=dbs_a_def_rwt_hsch_vapi_tkin_p1_i0'>Pale Angel</a>, is available on Amazon and The Proteus Knife, a novel, will be released soon.",
      articles: ["HowToKeepYourWritingOnTrack", "StayingCreativeWhileTheWorldIsBurning", "AttendingTheMuseOrYourWritingDiscipline"]
    },
    {
      id: "TheaPueschel",
      name: "Thea Pueschel",
      initials: "TP",
      bio:
        "Thea Pueschel is a writer and multimedia artist in Southern California. Thea writes ESL readers to pay the bills and literary fiction to feed the soul. Thea won the TAEM Flash Fiction Summer 2020 Contest for the pandemic based satire “Safer at Home.”",
      articles: ["SubmissionsTheHarshRealityAndHowToImproveYourOdds"]
    }
  ];
}
